import axios from "axios";
import authHeader from '@/services/auth-header.js';
import rutas_admin from "../../views/usuarios/permisos/rutas_admin";
import store from '../store';
import SideBarItems from "../../layouts/full-layout/sidebar/SidebarItems";

export default {
    namespaced: true,

    state: {
        session_ROL_COD: null,
        session_ROL_NOMBRE: null,
        session_PERMISOS: [],
        //auxiliares
        session_router_list: ["/login", "/sinPermiso", "/Accesos/perfil", "/Accesos", "/Accesos/perfil"],
        session_router_cod: ["10", "100", "101", "102", "9", "94"],
        session_VerticalSideBarItems: SideBarItems,
        isAdmin: false,

    },

    mutations: { // text, multiline, color, timeout

        actualizar_session_PERMISOS(state) { //PRIMERO  
            // state.session_PERMISOS=[];
            state.session_ROL_COD = store.state.auth.user.user.id_rol;
            state.session_ROL_NOMBRE = store.state.auth.user.user.rol;
            if (state.session_ROL_COD != undefined && state.session_ROL_COD != null) {
                if (state.session_ROL_COD >= 0) { // NO  ES ADMINISTRADOR
                    //console.log("usted posee un rol válido");
                    state.isAdmin = false;
                    axios
                        .get("rol/list/" + state.session_ROL_COD + "/", authHeader())
                        .then(function (response) {
                            // actualiza permisos
                            state.session_PERMISOS = Object.values(response.data.PERMISOS).map((item) => {

                                let recurso = {
                                    'name': item.PERM_NOMBRE,
                                    'to': item.PERM_ENDPOINT,
                                    'id': item.PERM_ID,
                                };
                                return recurso;
                            });

                            // actualiza para router
                            state.session_router_list = Object.values(response.data.PERMISOS).map((item) =>
                                item.PERM_ENDPOINT);
                            // actualiza para vertical sideBar
                            state.session_router_cod = Object.values(response.data.PERMISOS).map((item) =>
                                item.PERM_ID.toString());

                            localStorage.setItem("permitPages", this.session_router_list);
                        });
                } else {
                    state.isAdmin = true;
                    if (state.session_ROL_NOMBRE.toString() === "Molinero") {
                        //console.log("usted es Molinero");
                        let aux = rutas_admin;
                        aux.push({"id": 101, "name": "Configuración", "to": "/configuraciones"});
                        aux.push({"id": 102, "name": "Generales", "to": "/configuraciones/generales"});
                        state.session_PERMISOS = aux;
                        // actualiza para router
                        state.session_router_list = Object.values(state.session_PERMISOS).map((item) =>
                            item.to);
                        // actualiza para vertical sideBar
                        state.session_router_cod = Object.values(state.session_PERMISOS).map((item) =>
                            item.id.toString());
                    } else {
                        //console.log("usted es Administrador");
                        state.session_PERMISOS = rutas_admin;
                        // actualiza para router
                        state.session_router_list = Object.values(state.session_PERMISOS).map((item) =>
                            item.to);
                        // actualiza para vertical sideBar
                        state.session_router_cod = Object.values(state.session_PERMISOS).map((item) =>
                            item.id.toString());
                    }
                }
            } else {
                console.log("rol no valido");
            }
        },
        resetear_sessionPermisos(state) {
            localStorage.removeItem('vuex');
            state.session_ROL_COD = null;
            state.session_ROL_NOMBRE = null;
            state.session_PERMISOS = [];
            //auxiliares
            state.session_router_list = ["/login", "/sinPermiso", "/Accesos/perfil", "/Accesos", "/Accesos/perfil"];
            state.session_router_cod = ["10", "100", "101", "102", "9", "94"];
        },

        set_session_ROL_COD(state, payload) {
            state.session_ROL_COD = payload;
        },
        set_session_ROL_NOMBRE(state, payload) {
            state.session_ROL_NOMBRE = payload;
        },
        set_session_VerticalSideBarItems(state, payload) {
            state.session_VerticalSideBarItems = payload;
        },
    },

    actions: {
        notificar_action({commit}) {
            commit('notificar')
        },
        cerrar_notificacion_action({commit}) {
            commit('cerrarNotificacion')
        },
    },
    getters: {},
}
