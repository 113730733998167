import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import notificaciones from "./modules/notificaciones";
import {auth} from './auth.module';
import permisos from './modules/permisos';
import session_permisos from './modules/session_permisos';
import authHeader from '@/services/auth-header.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        dark_theme: false,
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: "white",
        SidebarBg: "",
        navbarColor: "primary",

        // dato globalito
        agricultorSeleccionado: {AGR_COD: null, nombreCompleto: ""},
        clienteSeleccionado: {CLI_COD: null, nombreCompleto: ""},
        //Estado de cuentas cliente
        estadosCuentaxCliente: [],
        saldoxCliente: 0,
        //Estado de cuentas Agricultor
        estadosCuentaxAgricultor: [],
        saldoxAgricultor: 0,

        //indicadores
        indicadores: {
            total_ingresos: 0,
            total_habilitaciones: 0,
            total_pagos_vehiculos: 0,
            total_agricultores: 0,
            total_maquila: 0,
        },
        indi_produccion: [], // los productos que contienen sacos
        indi_produccionOk: [], //{data: null, categorias:null},

        total_ventas: 0,
        total_ventas_sacos: 0,
        total_cuentasxcobrar: 0,
        totalKilos: 0,
        //fin indicadores


        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],

        chartOptions: {
            theme: {mode: 'light', palette: 'palette1'},
            plotOptions: {bar: {horizontal: false, borderRadius: 10}},

            chart: {
                id: 'ind_produccion',
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                },
                tooltip: {
                    theme: "dark"
                },
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            },
            xaxis: {
                categories: []
            }

        },

        series: [{
            name: 'Total sacos',
            data: []
        }],

        op_agricultores: [],
        op_agricultoresNyA: [],

        op_clientes: [],
        op_clientesNyA: [""], // este se utiliza de nombres

        codigosSeleccionados: [],

    },
    mutations: {
        setTotalKilos(state, payload) {
            state.totalKilos = payload
        },
        actualizarSaldoxAgricultor(state) {
            state.saldoxAgricultor = 0; // los productos que contienen sacos
            if (
                state.agricultorSeleccionado.AGR_COD != undefined &&
                state.agricultorSeleccionado.AGR_COD != null
            ) {
                axios
                    .get("agr/saldo/" + state.agricultorSeleccionado.AGR_COD + "/", authHeader()) // aqui cambiar dcue/listEst/1/       dcue/listClie/1/
                    .then(function (response) {
                        state.saldoxAgricultor = response.data.SALDO;

                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                console.log("No se ha seleccionado agricultor");
            }
        },
        actualizarEstadoCuentaxAgricultor(state) {
            //estadosCuentaxCliente
            state.estadosCuentaxAgricultor = []; // los productos que contienen sacos
            if (
                state.agricultorSeleccionado.AGR_COD != undefined &&
                state.agricultorSeleccionado.AGR_COD != null
            ) {
                axios
                    .get("agr/dcue/listAgr/?AGR_COD=" + state.agricultorSeleccionado.AGR_COD, authHeader()) // aqui cambiar dcue/listEst/1/       dcue/listClie/1/
                    .then(function (response) {
                        state.estadosCuentaxAgricultor = response.data;
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else { //agr/cue/list/
                console.log("No se ha seleccionado cliente");

            }
        },
        actualizarSaldoxCliente(state) {
            //state.saldoxCliente= 0; // los productos que contienen sacos
            if (
                state.clienteSeleccionado.CLI_COD != undefined &&
                state.clienteSeleccionado.CLI_COD != null
            ) {
                axios
                    .get("cli/saldo/" + state.clienteSeleccionado.CLI_COD + "/", authHeader()) // aqui cambiar dcue/listEst/1/       dcue/listClie/1/
                    .then(function (response) {
                        state.saldoxCliente = response.data.SALDO;

                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                console.log("No se ha seleccionado cliente");
            }
        },
        actualizarEstadoCuentaxCliente(state) {
            //estadosCuentaxCliente
            state.estadosCuentaxCliente = []; // los productos que contienen sacos
            if (
                state.clienteSeleccionado.CLI_COD != undefined &&
                state.clienteSeleccionado.CLI_COD != null
            ) {
                axios
                    .get("dcue/listClie/" + state.clienteSeleccionado.CLI_COD + "/", authHeader()) // aqui cambiar dcue/listEst/1/       dcue/listClie/1/
                    .then(function (response) {
                        state.estadosCuentaxCliente = response.data;
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                console.log("No se ha seleccionado cliente");
            }
        },

        updateChart(state) { // AQUI SE ESTÁ TRABAJNDO ESE GRAFICO
            let newData = [];
            let newNames = [];
            let newCategories = [];
            state.indi_produccion = []; // los productos que contienen sacos
            if (
                state.agricultorSeleccionado.AGR_COD === undefined ||
                state.agricultorSeleccionado.AGR_COD === null
            ) {
                axios
                    .get("indi/produccionEnv/", authHeader())
                    .then(function (response) {
                        state.indi_produccion = response.data;

                        newCategories = Object.values(state.indi_produccion).map((item) => item.PRO_DESCRIPCION);

                        //rellenar newNames
                        let pas = 0;
                        Object.values(state.indi_produccion).map((item) => {

                            item.ENVASES.map((it) => {
                                if (newNames.includes(it)) {
                                    console.log("repetido");
                                } else {
                                    newNames[pas] = it;
                                    pas++;
                                }

                            });

                        });
                        // rellenar cadena de datos asociados a un envase
                        newData = newNames.map(item => { // para cada ENVASE
                                const container = {};
                                container['name'] = item;
                                let cadena = [];
                                let incidencia = 0;
                                Object.values(state.indi_produccion).forEach(function (elem) { // CADA PRODUCTO
                                    let index = -1;

                                    incidencia = 0; // este es importante :)
                                    elem.ENVASES.map(item2 => { // se revisa de la lista de envases de cada producto
                                        index++;
                                        if (item2 === item) {
                                            cadena.push(elem.DATA[index]);
                                            incidencia++;
                                            // return;

                                        }//return item2
                                    });

                                    if (incidencia <= 0) { // si no se encuentra en la lista, el envase requerido, 0
                                        cadena.push(0);
                                    }

                                }); //FIN INDI

                                if (cadena != null)
                                    container['data'] = cadena; //THIS

                                return container;
                            }
                        );

                        // ACTUALZACIÓN DE VALORES A GRÁFICA
                        state.chartOptions = {...state.chartOptions, ...{xaxis: {categories: newCategories,}}};
                        state.series = Object.values(newData).map(item => item);


                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                axios
                    .get("indi/produccionEnv/?AGR_COD=" + state.agricultorSeleccionado.AGR_COD, authHeader())
                    .then(function (response) {
                        state.indi_produccion = response.data;

                        newCategories = Object.values(state.indi_produccion).map((item) => item.PRO_DESCRIPCION);

                        //rellenar newNames
                        let pas = 0;
                        Object.values(state.indi_produccion).map((item) => {

                            item.ENVASES.map((it) => {
                                if (newNames.includes(it)) {
                                    console.log("repetido");
                                } else {
                                    newNames[pas] = it;
                                    pas++;
                                }

                            });

                        });
                        // rellenar cadena de datos asociados a un envase
                        newData = newNames.map(item => { // para cada ENVASE
                                const container = {};
                                container['name'] = item;
                                let cadena = [];
                                let incidencia = 0;
                                Object.values(state.indi_produccion).forEach(function (elem) { // CADA PRODUCTO
                                    let index = -1;


                                    elem.ENVASES.map(item2 => { // se revisa de la lista de envases de cada producto
                                        index++;
                                        if (item2 === item) {
                                            cadena.push(elem.DATA[index]);
                                            incidencia++;
                                            return;

                                        }
                                        return item2
                                    });

                                    if (incidencia <= 0) { // si no se encuentra en la lista, el envase requerido, 0
                                        cadena.push(0);
                                    }

                                }); //FIN INDI

                                if (cadena != null)
                                    container['data'] = cadena; //THIS

                                return container;
                            }
                        );

                        // ACTUALZACIÓN DE VALORES A GRÁFICA
                        state.chartOptions = {...state.chartOptions, ...{xaxis: {categories: newCategories,}}};
                        state.series = Object.values(newData).map(item => item);


                    })
                    .catch(err => {
                        console.log(err);
                    });
            }


        },

        SET_DARK_THEME(state) {
            if (state.dark_theme)
                state.dark_theme = false;
            else
                state.dark_theme = true;
        },
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload;
        },

        // para agricultorrr
        SET_AGRICULTOR(state, payload) {
            state.agricultorSeleccionado = payload;
        },
        // para cliente
        SET_CLIENTE(state, payload) {
            state.clienteSeleccionado = payload;
        },

        SET_CONJUNTO(state, payload) {
            state.codigosSeleccionados = payload;
        },
        SET_INDICADORES(state, payload) {
            state.indicadores = payload;
        },

        actualizarIngresos(state) {
            state.indicadores.total_ingresos = null;
            state.indicadores.total_ingresos_por_comprar = null;
            if (
                state.agricultorSeleccionado.AGR_COD === undefined ||
                state.agricultorSeleccionado.AGR_COD === null
            ) {
                axios
                    .get("cas/total/?LT_ESTADO=ACTIVO&LT_LIQUIDADO=false", authHeader())
                    .then(function (response) {
                        state.indicadores.total_ingresos =
                            response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + " Kg.";
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
                axios
                    .get("cas/total/?LT_COD__isnull=true&REC_ESTADO=ACTIVO&VENTA_CAS_COD__isnull=true", authHeader())
                    .then(function (response) {
                        state.indicadores.total_ingresos_por_comprar =
                            response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + " Kg.";
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            } else {
                axios
                    .get(`cas/total/?LT_ESTADO=ACTIVO&LT_LIQUIDADO=false&AGR_COD=${state.agricultorSeleccionado.AGR_COD}`, authHeader())
                    .then(function (response) {
                        state.indicadores.total_ingresos =
                            response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + " Kg.";
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
                axios
                    .get(`cas/total/?LT_COD__isnull=true&REC_ESTADO=ACTIVO&VENTA_CAS_COD__isnull=true&AGR_COD=${state.agricultorSeleccionado.AGR_COD}`, authHeader())
                    .then(function (response) {
                        state.indicadores.total_ingresos_por_comprar =
                            response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + " Kg.";
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }


        },

        actualizarVentas(state) {

            axios
                .get("vent/total/", authHeader())
                .then(function (response) {
                    state.total_ventas = " S/ " +
                        response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + "";
                    console.log(response.data);
                })
                .catch(function (err) {
                    console.log(err);
                });


        },
        actualizarVentasSacos(state) {

            axios
                .get("vent/total/sacos/", authHeader())
                .then(function (response) {
                    state.total_ventas_sacos = "" +
                        response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + " sacos";
                    console.log(response.data);
                })
                .catch(function (err) {
                    console.log(err);
                });


        },
        actualizarCuentasxCobrar(state) {

            axios
                .get("vent/clientes/por_pagar/", authHeader())
                .then(function (response) {
                    state.total_cuentasxcobrar = " S/ " +
                        response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + "";
                    console.log(response.data);
                })
                .catch(function (err) {
                    console.log(err);
                });


        },
        showNumber(x) { // .toFixed(2) solo para 2 decimales dado un num
            return x.toLocaleString(undefined, {maximumFractionDigits: 2});
        },
        actualizarHabilitaciones(state) {
            if (
                state.agricultorSeleccionado.AGR_COD === undefined ||
                state.agricultorSeleccionado.AGR_COD === null
            ) {
                axios
                    .get("hab/totalHab/", authHeader())
                    .then(function (response) {
                        state.indicadores.total_habilitaciones = response.data.TOTAL;
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            } else {
                axios
                    .get("agr/totalHabAgr/" + state.agricultorSeleccionado.AGR_COD + "/", authHeader())
                    .then(function (response) {
                        state.indicadores.total_habilitaciones = response.data.TOTAL;
                        console.log(response.data);
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        },
        listarPagosVarios(state) {
            axios
                .get("pago_serv/list/", authHeader())
                .then(function (response) {
                    state.listaPagosVarios = response.data;
                    console.log(response.data);
                })
                .catch((err) => {
                    console.log(err.response.data);
                });
        }, //fin listar
        actualizarMaquila(state) {
            let url = "part/total/";
            if (state.agricultorSeleccionado.AGR_COD === undefined || state.agricultorSeleccionado.AGR_COD === null){
                url += ''
            } else {
                url += `?AGR_COD=${state.agricultorSeleccionado.AGR_COD}`
            }
            axios.get(url, authHeader()).then(function (response) {
                    state.indicadores.total_maquila = " S/ " + response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + "";
                })
                .catch(function (err) {
                    console.log(err);
                });
        },

        actualizarNroAgricultores(state) {  // aqui
            axios
                .get("agr/total/", authHeader())
                .then(function (response) {
                    state.indicadores.total_agricultores = " " +
                        response.data.TOTAL.toLocaleString(undefined, {maximumFractionDigits: 2}) + "";
                    console.log(response.data);
                })
                .catch(function (err) {
                    console.log(err);
                });
        },

        listarAgricultores(state) {
            state.op_agricultoresNyA = [],

                axios
                    .get("agr/list/", authHeader())
                    .then(function (response) {
                        state.op_agricultores = response.data;
                        for (let i = 0; i < state.op_agricultores.length; i++) {
                            state.op_agricultoresNyA.push({
                                nombreCompleto:
                                    state.op_agricultores[i].AGR_NOM +
                                    " " +
                                    state.op_agricultores[i].AGR_APE,
                                AGR_COD: state.op_agricultores[i].AGR_COD,
                            });
                        }
                        console.log(state.op_agricultoresNyA);
                    })
                    .catch(function (err) {
                        console.log(err.response.data);
                    });
        }, //fin listar
        listarClientes(state) {

            axios
                .get("cli/list/", authHeader())
                .then(function (response) {
                    state.op_clientesNyA = response.data;//.map((item) => item.CLI_NOM + " "+item.CLI_APE );

                    console.log(state.op_clientesNyA);
                })
                .catch(function (err) {
                    console.log(err.response.data);
                });
        }, //fin listar

    },

    actions: {

        refrescarEstadoCuentaxCliente({commit}) {
            commit('actualizarEstadoCuentaxCliente');
            commit('actualizarSaldoxCliente')
        },
        refrescarEstadoCuentaxAgricultor({commit}) {
            commit('actualizarEstadoCuentaxAgricultor');
            commit('actualizarSaldoxAgricultor')
        },


        refrescarIndicadores({commit}) {
            commit('actualizarIngresos');
            commit('actualizarHabilitaciones');
            commit('actualizarNroAgricultores');
            commit('actualizarMaquila')

        },
        refrescarIndiProduccion({commit}) {
            // commit('actualizarProduccion')
            commit('updateChart')

        },
        refrescarVentas({commit}) {
            // commit('actualizarProduccion')
            commit('actualizarVentas'),
                commit('actualizarVentasSacos')

        },
        refrescarCuentasxCobrar({commit}) {
            commit('actualizarCuentasxCobrar')
        },

        refrescarIngresos({commit}) {
            commit('actualizarIngresos')
        },
        refrescarHabilitaciones({commit}) {
            commit('actualizarHabilitaciones')
        },
        refrescarMaquila({commit}) {
            commit('actualizarMaquila')
        },
        refrescarNroAgricultores({commit}) {
            commit('actualizarNroAgricultores')
        },
        listarAgricultores({commit}) {
            commit('listarAgricultores')
        },
        listarClientes({commit}) {
            commit('listarClientes')
        },


    },
    getters: {},
    modules: {
        notificaciones,
        auth,
        permisos,
        session_permisos,

    },
    //import createPersistedState from "vuex-persistedstate";
    plugins: [createPersistedState()]
});
