import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import "./plugins/base";
import axios from 'axios';
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import Vue2Filters from "vue2-filters";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_APIURL || "https://emolinos.com/api/";
Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.use(Vue2Filters);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
