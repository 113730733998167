import axios from 'axios';
import authHeader from './auth-header';

class AuthService {
  login(user) {
    return axios
      .post('auth/login/', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        console.log(response.data);

        if (response.data.token) { // accessToken
          let usuario = {
            'id': response.data.user.id,
            'email': response.data.user.email,
            'username': response.data.user.username,
            'firs_tname': response.data.user.firstname,
            'las_tname': response.data.user.lastname,
            'telefono': response.data.user.telefono,
            'token': response.data.token,
            'expiry': response.data.expiry,
            'rol': response.data.rol,
           //'idRol': response.data.groups[0]['id'],
            'EMP_COD': response.data.EMP_COD,
            'EMP_NOMBRE': response.data.EMP_NOMBRE,  
          };
          localStorage.setItem("user", JSON.stringify(usuario));
          

        }

        return response.data;
      });
  }

  logout() {    

    axios.post('auth/logout/', null, authHeader())
      .then((res) => {
        console.log(res.data)

      }).catch((error) => {
       console.log(error);

      })
    localStorage.removeItem('user');
    localStorage.removeItem('vuex');
  }

}

export default new AuthService();
