// Permisos

export default [
	{ "id": 10, "name": "Públicos", "to": "", "locked": "true", "children": [{ "id": 100, "name": "login", "to": "/login", "locked": "true" },] },
	{ "id": 100, "name": "login", "to": "/login", "locked": "true" },

	{
		"id": 1, "to": "/tableros", "name": "Tableros", "children": [{ "id": 11, "name": "Indicadores", "to": "/tableros/indicadores" }
		]
	}, 
	
	
	{ "id": 11, "name": "Indicadores", "to": "/tableros/indicadores" },
	{ "id": 299, "name": "Transporte", "to": "/tableros/transporte" },
	{ "id": 300, "name": "Servicios", "to": "/tableros/servicio" },
	{ "id": 301, "name": "Ventas", "to": "/tableros/ventas" },
	
	{
		"id": 2, "to": "/produccion", "name": "Produccion", "children": [
			{ "id": 21, "name": "Producción", "to": "/produccion/produccion" },
			{ "id": 22, "name": "Partes Diarios de Produccion", "to": "/produccion/partesDiariosProduccion" },
			{ "id": 23, "name": "Almacén", "to": "/produccion/almacen" },
			{ "id": 24, "name": "Inventario inicial", "to": "/produccion/inventarioInicial" },
			{ "id": 25, "name": "Ingresos Para Reproceso", "to": "/produccion/ingresosReproceso" },
			{ "id": 26, "name": "Reproceso", "to": "/produccion/reproceso" },
			{ "id": 27, "name": "Salida", "to": "/produccion/salida" },
		]
	},
	{ "id": 21, "name": "Producción", "to": "/produccion/produccion" },
	{ "id": 22, "name": "Partes Diarios de Produccion", "to": "/produccion/partesDiariosProduccion" },
	{ "id": 23, "name": "Almacén", "to": "/produccion/almacen" },
	{ "id": 24, "name": "Inventario inicial", "to": "/produccion/inventarioInicial" },
	{ "id": 25, "name": "Ingresos Para Reproceso", "to": "/produccion/ingresosReproceso" },
	{ "id": 26, "name": "Reproceso", "to": "/produccion/reproceso" },
	{ "id": 27, "name": "Salida", "to": "/produccion/salida" },
	{
		"id": 3, "to": "/servicios", "name": "Servicios",
		"children": [{ "id": 31, "name": "Transporte", "to": "/servicios/transporte", "children":
				[{ "id": 311, "name": "Pagos", "to": "/servicios/transporte" }] }]
	},
	{ "id": 31, "name": "Transporte", "to": "/servicios/transporte", "children":
			[{ "id": 311, "name": "Pagos", "to": "/servicios/transporte" }] },
	{ "id": 311, "name": "Pagos", "to": "/servicios/transporte" },
	{ "id": 32, "name": "Varios", "to": "/servicios/varios" },
	{ "id": 321, "name": "Varios", "to": "/servicios/varios" },

	{ "id": 4, "to": "/ventas", "name": "Ventas",
		"children": [
			{ "id": 41, "name": "Ventas", "to": "/ventas/ventas" },
			{ "id": 42, "name": "Ventas Otros", "to": "/ventas/ventas_otros" }
		]
	},
	{ "id": 41, "name": "Ventas", "to": "/ventas/ventas" },
	{ "id": 42, "name": "Ventas Otros", "to": "/ventas/ventas_otros" },

	{ "id": 99, "name": "Compras", "to": "/compras", "children": [
			{ "id": 990, "name": "Compras", "to": "/compras/compras" }
		] },
	{ "id": 990, "name": "Compras", "to": "/compras/compras" },

	{ "id": 200, "name": "Proveedores", "to": "/proveedores", "children": [
			{ "id": 201, "name": "Proveedores", "to": "/proveedores/proveedores" },
			{ "id": 202, "name": "Proveedores", "to": "/proveedores/cuentas" }
		] },
	{ "id": 201, "name": "Proveedores", "to": "/proveedores/proveedores" },
	{ "id": 202, "name": "Proveedores cuentas", "to": "/proveedores/cuentas" },

	{ "id": 40, "name": "CuentasxPagar", "to": "/cuentasxpagar" },
	{ "id": 401, "name": "Bancos", "to": "/cuentasxpagar/bancos" },
	{ "id": 4011, "name": "Bancos", "to": "/cuentasxpagar/bancos/bancos" },
	{ "id": 4012, "name": "Prestamos", "to": "/cuentasxpagar/bancos/prestamos" },
	{ "id": 4013, "name": "Cuotas", "to": "/cuentasxpagar/bancos/cuotas" },
	{ "id": 4014, "name": "Pendientes", "to": "/cuentasxpagar/bancos/pendientes" },

	{ "id": 5, "to": "/agricultores", "name": "Agricultores", "children": [{ "id": 51, "name": "Estados de cuentas", "to": "/agricultores/estado_cuenta_agricultor" }, { "id": 52, "name": "Agricultores", "to": "/agricultores/agricultores" }, { "id": 53, "name": "Lotes", "to": "/agricultores/lotes" }, { "id": 54, "name": "Arroz cáscara", "to": "/agricultores/arrozcascara" }, { "id": 55, "name": "Habilitaciones", "to": "/agricultores/habilitaciones" }] },
	{ "id": 51, "name": "Estados de cuentas", "to": "/agricultores/estado_cuenta_agricultor" },
	{ "id": 52, "name": "Agricultores", "to": "/agricultores/agricultores" },
	{ "id": 53, "name": "Lotes", "to": "/agricultores/lotes" },
	{ "id": 54, "name": "Arroz cáscara", "to": "/agricultores/arrozcascara" },
	{ "id": 55, "name": "Habilitaciones", "to": "/agricultores/habilitaciones" },
	
	{ "id": 56, "name": "Liquidaciones", "to": "/agricultores/liquidaciones" },
	{ "id": 561, "name": "Liquidaciones", "to": "/agricultores/liquidaciones/liquidar" },
	{ "id": 562, "name": "Liquidaciones", "to": "/agricultores/liquidaciones/liquidaciones" },

	{ "id": 876, "name": "Cascara Arroz", "to": "/agricultores/cascara" },
	{ "id": 877, "name": "Ingreso Cascara", "to": "/agricultores/cascara/ingreso" },
	{ "id": 878, "name": "Compra Cascara", "to": "/agricultores/cascara/compra" },

	{ "id": 6, "to": "/clientes", "name": "Clientes", "children": [{ "id": 61, "name": "Clientes", "to": "/clientes/clientes" }, { "id": 62, "name": "Estados de cuentas", "to": "/clientes/estado_cuenta_cliente" }] },
	{ "id": 61, "name": "Clientes", "to": "/clientes/clientes" }, { "id": 62, "name": "Estados de cuentas", "to": "/clientes/estado_cuenta_cliente" },
	{ "id": 7, "to": "/trabajadores", "name": "Trabajadores", "children": [{ "id": 71, "name": "Trabajadores", "to": "/trabajadores/trabajadores" }] }, { "id": 71, "name": "Trabajadores", "to": "/trabajadores/trabajadores" },
	{ "id": 8, "to": "/mantenimiento", "name": "Mantenimiento", "children": [{ "id": 81, "name": "Zonas", "to": "/mantenimiento/zonas" }, { "id": 82, "name": "Variedades", "to": "/mantenimiento/variedades" }, { "id": 83, "name": "Molinos", "to": "/mantenimiento/molinos" }, { "id": 84, "name": "Vasijas", "to": "/mantenimiento/vasijas" }, { "id": 85, "name": "Productos", "to": "/mantenimiento/productos" }, { "id": 86, "name": "Vehículos", "to": "/mantenimiento/vehiculos" }] },
	{ "id": 81, "name": "Zonas", "to": "/mantenimiento/zonas" },
	{ "id": 82, "name": "Variedades", "to": "/mantenimiento/variedades" },
	{ "id": 83, "name": "Molinos", "to": "/mantenimiento/molinos" },
	{ "id": 84, "name": "Vasijas", "to": "/mantenimiento/vasijas" },
	{ "id": 85, "name": "Productos", "to": "/mantenimiento/productos" },
	{ "id": 86, "name": "Vehículos", "to": "/mantenimiento/vehiculos" },
	{ "id": 87, "name": "Servicios", "to": "/mantenimiento/servicios" },
	{ "id": 88, "name": "Insumos", "to": "/mantenimiento/insumos" },
	{ "id": 9, "to": "/Accesos", "name": "Accesos", "children": [{ "id": 91, "name": "Usuarios", "to": "/Accesos/usuarios" }, { "id": 92, "name": "Permisos", "to": "/Accesos/permisos" }, { "id": 93, "name": "Datos molino", "to": "/Accesos/datosMolino" }, { "id": 94, "name": "Datos personales", "to": "/Accesos/perfil", "locked": "true" }] },
	{ "id": 91, "name": "Usuarios", "to": "/Accesos/usuarios" },
	{ "id": 92, "name": "Permisos", "to": "/Accesos/permisos" },
	{ "id": 93, "name": "Datos molino", "to": "/Accesos/datosMolino" },
	{ "id": 94, "name": "Datos personales", "to": "/Accesos/perfil", "locked": "true" },
	{ "id": 101, "name": "Configuración", "to": "/configuraciones" },
	{ "id": 102, "name": "Generales", "to": "/configuraciones/generales" },
];
