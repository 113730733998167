

export default [
    // PARA PERMISOS EN ACCESOS
    {
        id: 10,
        name: 'Permisos mínimos',
        to: '',
        locked: "true",
        children: [
            {
                id: 100,
                name: "login",
                to: "/login",
                locked: "true",
                component: () => import("@/views/usuarios/login"),
            },
            {
                id: 9,
                name: "Accesos",
                to: "/Accesos",
            },

            {
                id: 94,
                name: "Datos personales",
                to: "/Accesos/perfil",
                locked: "true",
            }
        ],
    },
    //TABLEROS
    {
        id: 1,
        to: "/tableros",
        name: "Tableros",
        children: [
            {
                id: 11, // tableros indicadores
                name: "Indicadores",
                to: "/tableros/indicadores",
                component: () => import("@/views/dashboards/analytical/Analytical"), //
            }, // fin tableros indicadores
            {
                id: 299, // tableros indicadores
                name: "Transporte",
                to: "/tableros/transporte",
                component: () => import("@/views/dashboards/tableros/transporte/transporte"), //
            },
            {
                id: 300, // tableros indicadores
                name: "Servicios",
                to: "/tableros/servicio",
                component: () => import("@/views/dashboards/tableros/servicio/servicio"), //
            },
            {
                id: 301, // tableros indicadores
                name: "Ventas",
                to: "/tableros/ventas",
                component: () => import("@/views/dashboards/tableros/ventas/ventas"), //
            }, // fin tableros indicadores
        ],
    },

    { // produccion
        id: 2,
        to: "/produccion",
        name: "Produccion",
        children: [
            {
                id: 24,
                name: "Inventario inicial",
                to: "/produccion/inventarioInicial",
            },
            {
                id: 23,
                name: "Almacén",
                to: "/produccion/almacen",
            },
            {
                id: 21,
                name: "Producción",
                to: "/produccion/produccion",
            },
            {
                id: 22,
                name: "Partes Diarios de Produccion",
                to: "/produccion/partesDiariosProduccion",

            },
            {
                id: 25,
                name: "Ingresos Para Reproceso",
                to: "/produccion/ingresoReproceso",
            },
            {
                id: 26,
                name: "Reproceso",
                to: "/produccion/reproceso",
            },
            {
                id: 27,
                name: "Salida",
                to: "/produccion/salida",
            },

        ],
    },// fin produccion

    { // servicios
        id: 3,
        to: "/servicios",
        name: "Servicios",
        children: [

            { // transporte                
                id: 31,
                name: "Transporte",
                to: "/servicios/transporte",
            },// fin transporte
            { // otros servicios                
                id: 32,
                name: "Varios",
                to: "/servicios/varios",
            },// fin otros servicios
        ],
    },// fin servicios

    { // Ventas>
        id: 4,
        to: "/ventas",
        name: "Ventas",
        children: [
            {
                id: 41,
                name: "Ventas Arroz",
                to: "/ventas/ventas",
            },
            {
                id: 42,
                name: "Ventas Otros",
                to: "/ventas/ventas_otros",
            }

        ],
    },// Ventas
    { //Compras
        id: 99,
        to: "/compras",
        name: "Compras",
        children: [
            {
                id: 990,
                name: "Compras",
                to: "/compras/compras",
            }
        ],
    },// Compras >
    { //Proveedores
        id: 200,
        to: "/proveedores",
        name: "Proveedores",
        children: [
            {
                id: 201,
                name: "Proveedores",
                to: "/proveedores/proveedores",
            },
            {
                id: 202,
                name: "Cuentas",
                to: "/proveedores/cuentas",
            }
        ],
    },// Proveedores >
    { // cuentas x pagar
        id: 40,
        to: "/cuentasxpagar",
        name: "Cuentas por pagar",
        children: [

            { // bancos                
                id: 401,
                name: "Bancos",
                to: "/cuentasxpagar/bancos",
                children: [

                    {
                        id: 4011,
                        name: "Bancos",
                        to: "/cuentasxpagar/bancos/bancos",
                    },
                    {
                        id: 4012,
                        name: "Prestamos",
                        to: "/cuentasxpagar/bancos/prestamos",
                    },
                    {
                        id: 4013,
                        name: "Cuotas",
                        to: "/cuentasxpagar/bancos/cuotas",
                    },
                    {
                        id: 4014,
                        name: "Pendientes",
                        to: "/cuentasxpagar/bancos/pendientes",
                    }

                ],
            },// fin bancos
        ],
    },// fin cuentas x pagar
    { // agricultores
        id: 5,
        to: "/agricultores",
        name: "Agricultores",
        children: [
            {
                id: 51,
                name: "Estados de cuentas",
                to: "/agricultores/estado_cuenta_agricultor",
            },
            {
                id: 52,
                name: "Agricultores",
                to: "/agricultores/agricultores",
            },
            {
                id: 53,
                name: "Lotes",
                to: "/agricultores/lotes",
            },
            {
                id: 54,
                name: "Arroz cáscara",
                to: "/agricultores/arrozcascara",
            },
            {
                id: 55,
                name: "Habilitaciones",
                to: "/agricultores/habilitaciones",
            },
            {
                id: 56,
                name: "Liquidaciones",
                to: "/agricultores/liquidaciones",
            },
            {
                id: 56,
                name: "Liquidaciones",
                to: "/agricultores/liquidaciones",
                children: [

                    {
                        id: 561,
                        name: "Liquidar",
                        to: "/agricultores/liquidaciones/liquidar",
                    },
                    {
                        id: 562,
                        name: "Liquidaciones",
                        to: "/agricultores/liquidaciones/liquidaciones",
                    }

                ],
            },
            {
                id: 876,
                name: "CascaraArroz",
                to: "/agricultores/cascara",
                children: [

                    {
                        id: 877,
                        name: "Ingreso Cascara",
                        to: "/agricultores/cascara/ingreso",
                    },
                    {
                        id: 878,
                        name: "Compra Cascara",
                        to: "/agricultores/cascara/compra",
                    }

                ],
            }


        ],
    },// fin agricultores
    { // clientes
        id: 6,
        to: "/clientes",
        name: "Clientes",
        children: [
            {
                id: 61,
                name: "Clientes",
                to: "/clientes/clientes",
            },
            {
                id: 62,
                name: "Estados de cuentas",
                to: "/clientes/estado_cuenta_cliente",
            }

        ],
    },// clientes


    { // trabajadores
        id: 7,
        to: "/trabajadores",
        name: "Trabajadores",
        children: [
            {
                id: 71,
                name: "Trabajadores",
                to: "/trabajadores/trabajadores",
            }

        ],
    },// fin trabajadores
    { // MANTENIMIENTO
        id: 8,
        to: "/mantenimiento",
        name: "Mantenimiento",
        children: [
            {
                id: 81,
                name: "Zonas",
                to: "/mantenimiento/zonas",
            },
            {
                id: 82,
                name: "Variedades",
                to: "/mantenimiento/variedades",
            },
            {
                id: 83,
                name: "Molinos",
                to: "/mantenimiento/molinos",
            },
            {
                id: 84,
                name: "Vasijas",
                to: "/mantenimiento/vasijas",
            },
            {
                id: 85,
                name: "Productos",
                to: "/mantenimiento/productos",
            },
            {
                id: 86,
                name: "Vehículos",
                to: "/mantenimiento/vehiculos",
            },
            {
                id: 87,
                name: "Servicios",
                to: "/mantenimiento/servicios",
            },
            {
                id: 88,
                name: "Insumos",
                to: "/mantenimiento/insumos",
            },


        ],
    },// fin MANTENIMIENTO
    { // usuarios
        id: 9,
        to: "/Accesos",
        name: "Accesos",
        children: [
            {
                id: 94,
                name: "Datos personales",
                to: "/Accesos/perfil",
                locked: "true",
            },
            {
                id: 91,
                name: "Usuarios",
                to: "/Accesos/usuarios",
            },
            {
                id: 92,
                name: "Permisos",
                to: "/Accesos/permisos",
            },
            {
                id: 93,
                name: "Datos molino",
                to: "/Accesos/datosMolino",
            }


        ],
    },

];