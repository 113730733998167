export default function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));//

  if (user && user.token) {
    let config = {
      headers: {
        'Content-Type': 'application/json', //Content-Type: "application/json;charset=utf-8" 'content-type': "application/json",
      },
    };
  
    // If token, add to headers config
    if (user.token) {
      config.headers['Authorization'] = `token ${user.token}`;
    }

    return config; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
