import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@/scss/vuetify/overrides.scss";
import store from "../store/store";
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

const theme = {
  primary: "#006400", // #FF9100 change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  secondary: "#ffe082", // #caae53
  info: "#1e88e5", //#E9967A",//"#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: store.state.dark_theme,
  },
  lang: {
    locales: {es},
    current: 'es',
  },
  rtl: false,
});
