/**
 * my.notificar({text:'Se registro',color:'blue',timeout:5000});
 * */ 
export default{
  namespaced: true,

    state: {
        tipo:0,

        snackbar: {
          visible: false,
          color: "green",
          text: "Se registró correctamente",
          timeout: 4000,
          multiline: false
        },
        statSend:false,
    },

    mutations: { // text, multiline, color, timeout
        notificar(state, payload) {
          if(payload.tipo){ // TIPO DE MENSAJE
            switch(payload.tipo) {
              case 11: //agregar ok
                payload.text = "Se agregó correctamente";
                payload.color = "green"
                break;
              case 12:
                payload.text = "No se agregó el registro";
                payload.color = "error"
                break;
              case 21: //agregar ok
                payload.text = "Se editó correctamente";
                payload.color = "green"
                break;
              case 22:
                payload.text = "No se editó el registro";
                payload.color = "error"
                break;
              case 31: //agregar ok
                payload.text = "Se eliminó correctamente";
                payload.color = "green"
                break;
              case 32:
                payload.text = "No se eliminó el registro";
                payload.color = "error"
                break;
              case 55: //  no se usa
                payload.text = "El formulario ya se esta enviando...";
                payload.color = "pink"
                break;
              
              case 401:
                payload.text = "Usted no cuenta con autorización para realizar la acción...";
                payload.color = "error"
                break;
                
              default:
                // code block
            }

          }
          if (payload.text) {
            state.snackbar.text = payload.text;
          }
          
          // Si es mas de 50 caracteres definirlo como multilinea
          if (payload.multiline) {
            state.snackbar.multiline = payload.multiline;
          }
       
          // Color para mensajes de error o éxito
          if (payload.color) {
            state.snackbar.color = payload.color;
          }
    
         // Tiempo de duración
          if (payload.timeout) {
            state.snackbar.timeout = payload.timeout;
          }
          state.snackbar.visible = true;
       },
       cerrarNotificacion(state) {
          state.snackbar.visible = false;
          state.snackbar.multiline = false;
          state.snackbar.text = null;
          state.snackbar.color= "green";
        },
        checkAcceso(state) {
          if (!state.statSend) {
              state.statSend = true;
              return true;
          } else {
            return false;
          }
          },
    },

    actions: {  

    notificar_action({commit}){
      commit('notificar')
    },
    cerrar_notificacion_action({commit}){
      commit('cerrarNotificacion')
    },
  
    },
    getters: {
        
    },
  }
