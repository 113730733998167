import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store/store.js'

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            path: "/",

            redirect: "login",
            component: () => import("@/layouts/blank-layout/Blanklayout"),
            children: [
                {
                    name: "login",
                    path: "login",
                    component: () => import("@/views/usuarios/login"),
                },
                {
                    name: "SinPermiso",
                    path: "sinPermiso",
                    component: () => import("@/views/usuarios/SinPermiso"),
                },

            ]
        },

        {
            path: "/",
            redirect: "dashboard",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [

                // AGRICULTOR
                {
                    name: "Estado de cuenta",
                    path: "agricultores/estado_cuenta_agricultor",
                    component: () => import("@/views/agricultores/estado_cuenta/estadoCuentaAgricultor"),
                },
                {
                    name: "Agricultores",
                    path: "agricultores/agricultores",
                    component: () => import("@/views/agricultores/agricultores"),
                },
                // HABILITACIONES
                {
                    name: "Habilitaciones",
                    path: "agricultores/habilitaciones",
                    component: () => import("@/views/habilitaciones/habilitaciones"),
                },
                // FIN DE HABILITACIONES
                // LIQUIDACIONES
                {
                    name: "Liquidar",
                    path: "agricultores/liquidaciones/liquidar",
                    component: () => import("@/views/agricultores/liquidaciones/liquidar"),
                },
                {
                    name: "Liquidaciones",
                    path: "agricultores/liquidaciones/liquidaciones",
                    component: () => import("@/views/agricultores/liquidaciones/liquidaciones"),
                },
                // FIN DE LIQUIDACIONES

                // CASCARA
                {
                    name: "Compra Cascara",
                    path: "agricultores/cascara/ingreso",
                    component: () => import("@/views/agricultores/cascara_arroz/ingreso_cascara"),
                },
                {
                    name: "Ingreso Cascara",
                    path: "agricultores/cascara/compra",
                    component: () => import("@/views/agricultores/cascara_arroz/compra_cascara"),
                },
                // FIN DE CASCARA

                {
                    name: "Lotes",
                    path: "agricultores/lotes",
                    component: () => import("@/views/ingresos/lotes"),
                },
                {
                    name: "ArrozCascara",
                    path: "agricultores/arrozcascara",
                    component: () => import("@/views/ingresos/arrozcascara"),
                },
                // FIN DE AGRICULTOR

                // CLIENTES
                {
                    name: "Estados de cuenta",
                    path: "clientes/estado_cuenta_cliente",
                    component: () => import("@/views/ventas/clientes/estadoCuentaCliente"),
                },
                {
                    name: "Clientes",
                    path: "clientes/clientes",
                    component: () => import("@/views/ventas/clientes/clientes"),
                },
                // FIN CLIENTES
                // VENTAS
                {
                    name: "Ventas",
                    path: "ventas/ventas",
                    component: () => import("@/views/ventas/ventas/ventaProducto"),
                },
                {
                    name: "Ventas Otros",
                    path: "ventas/ventas_otros",
                    component: () => import("@/views/ventas/ventas_otros/ventaOtros"),
                },
                // FIN DE VENTAS

                // SERVICIOS
                // TRANSPORTE

                {
                    name: "Pagos",
                    path: "servicios/transporte",
                    component: () => import("@/views/servicios/transportes/pago-tranportes"),
                },
                // OTROS SERVICIOS
                {
                    name: "Varios",
                    path: "servicios/varios",
                    component: () => import("@/views/servicios/varios/pago-servicios"),
                },
                // FIN SERVICIOS

                // CUENTAS POR PAGAR
                // BANCOS

                {
                    name: "Bancos",
                    path: "cuentasxpagar/bancos/bancos",
                    component: () => import("@/views/bancos/bancos"),
                },
                {
                    name: "Prestamos",
                    path: "cuentasxpagar/bancos/prestamos",
                    component: () => import("@/views/bancos/prestamos"),
                },
                {
                    name: "Cuotas",
                    path: "cuentasxpagar/bancos/cuotas",
                    component: () => import("@/views/bancos/cuotas"),
                },
                {
                    name: "Pendientes",
                    path: "cuentasxpagar/bancos/pendientes",
                    component: () => import("@/views/bancos/calendario"),
                },

                // FIN CUENTAS POR PAGAR

                // COMPRAS
                {
                    name: "Compras",
                    path: "compras/compras",
                    component: () => import("@/views/compras/compras"),
                },
                // FIN COMPRAS

                // PROVEEDORES
                {
                    name: "Proveedores",
                    path: "proveedores/proveedores",
                    component: () => import("@/views/proveedores/proveedores"),
                },
                {
                    name: "Cuentas Proveedores",
                    path: "proveedores/cuentas",
                    component: () => import("@/views/proveedores/estados"),
                },
                // FIN PROVEEDORES

                // TRABAJADORES
                {
                    name: "Trabajadores",
                    path: "trabajadores/trabajadores",
                    component: () => import("@/views/trabajadores/trabajadores"),
                },

                // FIN DE TRABAJADORES
                // MANTENIMIENTO

                {
                    name: "Zonas",
                    path: "mantenimiento/zonas",
                    component: () => import("@/views/mantenimiento/zonas/zonas"),
                },
                {
                    name: "Variedades",
                    path: "mantenimiento/variedades",
                    component: () =>
                        import("@/views/mantenimiento/variedades/variedades"),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    name: "Molinos",
                    path: "mantenimiento/molinos",
                    component: () => import("@/views/mantenimiento/molinos/molinos"),
                },
                {
                    name: "Vasijas",
                    path: "mantenimiento/vasijas",
                    component: () => import("@/views/mantenimiento/vasijas/vasijas"),
                },
                {
                    name: "Productos",
                    path: "mantenimiento/productos",
                    component: () => import("@/views/mantenimiento/productos/productos"),
                },
                {
                    name: "Vehiculos",
                    path: "mantenimiento/vehiculos",
                    component: () => import("@/views/mantenimiento/vehiculos/vehiculos"),
                },
                {
                    name: "Servicios",
                    path: "mantenimiento/servicios",
                    component: () => import("@/views/mantenimiento/tipo_servicios/tipos_servicios"),
                },
                {
                    name: "Insumos",
                    path: "mantenimiento/insumos",
                    component: () => import("@/views/mantenimiento/insumos/insumos"),
                },
                // FIN DE MANTENIMIENTO

                // USUARIOS
                {
                    name: "Usuarios",
                    path: "Accesos/usuarios",
                    component: () => import("@/views/usuarios/usuarios"),
                },
                {
                    name: "Permisos",
                    path: "Accesos/permisos",
                    component: () => import("@/views/usuarios/permisos"),
                },
                {
                    name: "Datos molino",
                    path: "Accesos/datosMolino",
                    component: () => import("@/views/usuarios/molino"),
                },
                {
                    name: "Datos personales",
                    path: "Accesos/perfil",
                    component: () => import("@/views/usuarios/perfil"),
                },

                // FIN DE USUARIOS

                // CONFIGURACIONES
                {
                    name: "Configuraciones",
                    path: "configuraciones/generales",
                    component: () => import("@/views/configuraciones/generales"),
                },
                // FIN CONFIGURACIONES

                {
                    name: "Indicadores",
                    path: "tableros/indicadores",
                    component: () => import("@/views/dashboards/analytical/Analytical"), //
                },
                {
                    name: "Servicio",
                    path: "tableros/servicio",
                    component: () => import("@/views/dashboards/tableros/servicio/servicio"),
                },
                {
                    name: "Transporte",
                    path: "tableros/transporte",
                    component: () => import("@/views/dashboards/tableros/transporte/transporte"),
                },
                {
                    name: "Ventas_tablero",
                    path: "tableros/ventas",
                    component: () => import("@/views/dashboards/tableros/ventas/ventas"),
                },

                // ATAJOS :ad
                {
                    name: "PartesDiariosProduccionad",
                    path: "produccion/partesDiariosProduccionad/:ad",
                    component: () => import("@/views/servicios/maquila/partesDiariosProduccion"),
                },
                {
                    name: "Agricultoresad",
                    path: "agricultores/agricultoresad/:ad",
                    component: () => import("@/views/agricultores/agricultores"),
                },
                {
                    name: "Habilitacionesad",
                    path: "agricultores/habilitacionesad/:ad",
                    component: () => import("@/views/habilitaciones/habilitaciones"),
                },
                {
                    name: "ArrozCascarad",
                    path: "agricultores/arrozcascarad/:ad",
                    component: () => import("@/views/ingresos/arrozcascara"),
                },
                {
                    name: "Clientesad",
                    path: "clientes/clientesad/:ad",
                    component: () => import("@/views/ventas/clientes/clientes"),
                },
                {
                    name: "Ventasad",
                    path: "ventas/ventasad/:ad",
                    component: () => import("@/views/ventas/ventas/ventaProducto"),
                },
                {
                    name: "Pagosad",
                    path: "servicios/transporte/pagosad/:ad",
                    component: () => import("@/views/servicios/transportes/pago-tranportes"),
                },
                // --
            ],
        },

        {
            path: "/produccion",
            redirect: "produccion",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Produccion",
                    path: "produccion",
                    component: () => import("@/views/produccion/produccion"),
                },
                {
                    name: "inventarioInicial",
                    path: "inventarioInicial",
                    component: () => import("@/views/produccion/inventario_inicial"),
                },
                {
                    name: "Almacen",
                    path: "almacen",
                    component: () => import("@/views/produccion/almacen"),
                },
                {
                    name: "PartesDiariosProduccion",
                    path: "partesDiariosProduccion",
                    component: () => import("@/views/servicios/maquila/partesDiariosProduccion"),
                },
                {
                    name: "ingresosReproceso",
                    path: "ingresosReproceso",
                    component: () => import("@/views/produccion/ingresos_reproceso/ingresos_reproceso"),
                },
                {
                    name: "reproceso",
                    path: "reproceso",
                    component: () => import("@/views/produccion/reproceso/reproceso"),
                },
                {
                    name: "salida",
                    path: "salida",
                    component: () => import("@/views/produccion/salidas/salida"),
                }
            ]
        },
        {
            path: "*",
            component: () => import("@/views/usuarios/SinPermiso"),
        },
    ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

let permitPages = store.state.session_permisos.session_router_list;

router.beforeEach((to, from, next) => {
    if (store.state.auth.status.loggedIn) {
        //next()
        permitPages = store.state.session_permisos.session_router_list;
        //console.log(permitPages)
        let beta = [''];

        if (permitPages.includes(to.path)||beta.includes(to.path)) {
            next();
        } else {
            next('/sinPermiso');
        }
    } else {
        next();
    }
});

export default router;
