import axios from "axios";
import authHeader from '@/services/auth-header.js';
//import createPersistedState from "vuex-persistedstate";
import rutas from '@/views/usuarios/permisos/rutas.js';

export default {
    namespaced: true,

    state: {
        rol_selected: {
            "ROL_COD": null,
            "ROL_NOMBRE": "",
            "PERMISOS": [ ] // solo los códigos
        },
        permisosSelected:[],

        roles: [],
        permisos: rutas,
        
        statSend: false,

    },

    mutations: { // text, multiline, color, timeout

        listarRoles(state){
            
                axios
                    .get("rol/list/",authHeader()) 
                    .then(function (response) {
                        state.roles = response.data;
                        
                    });
           
        },
        listarPermisosRol(state) {
            //state.rol_selected = [];
            if (
                state.rol_selected  != undefined &&
                state.rol_selected  != null &&
                state.rol_selected.ROL_COD != null
            ) {
                axios
                    .get("rol/list/"+state.rol_selected.ROL_COD+"/",authHeader()) 
                    .then(function (response) {

                        state.permisosSelected =  Object.values(response.data.PERMISOS).map((item) => {     
                                 
                            let recurso = {
                              'name':item.PERM_NOMBRE,
                              'to':item.PERM_ENDPOINT,
                              'id': item.PERM_ID,              
                            };
                            return recurso;
                          });
                    });
            } else {
                console.log("No se ha seleccionado ROL");
            }
        },

        checkAcceso(state) {
            if (!state.statSend) {
                state.statSend = true;
                return true;
            } else {
                //  alert("El formulario ya se esta enviando...");

                // state.snackbar.text = "El formulario ya se esta enviando...";
                // state.snackbar.color = "pink"
                // state.snackbar.visible = true; 
                return false;
            }
        },
        SET_ROL_SELECTED(state,payload){
            state.rol_selected = payload; 

        },
        SET_PERMISOS_SELECTED(state,payload){
            state.permisosSelected = payload; 

        },
        SET_ROLES(state,payload){
            state.roles = payload; 

        },
        SET_PERMISOS(state,payload){
            state.permisos = payload; 

        },
    },

    actions: {

        notificar_action({ commit }) {
            commit('notificar')
        },
        cerrar_notificacion_action({ commit }) {
            commit('cerrarNotificacion')
        },

    },
    getters: {

    },
   // plugins: [createPersistedState()]
}
