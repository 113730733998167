import AuthService from '../services/auth.service';
import moment from 'moment/moment';

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {
        status: {
            loggedIn: true
        },
        user //user.cia.membrete
    }
    : {
        status: {
            loggedIn: false
        },
        user: null
    };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },

    },
    mutations: {
        RESET_USER(state) {
            state.user = null;
        },
        SET_CIA(state, payload) {
            state.user.cia = payload;
        },
        SET_MEMBRETE(state, payload) {
            state.user.cia.membrete = [];
            let reporte = payload;
            let empresa = state.user.cia;
            let current = state.user.user;
            let usuario = current.username + " / " + current.first_name + ' ' + current.last_name;
            let fecha_larga = moment(new Date()).locale("es").format('LLLL');

            if (empresa.EMP_NOMBRE!==null && empresa.EMP_NOMBRE!== undefined){
                state.user.cia.membrete = [
                    empresa.EMP_NOMBRE.toUpperCase(),
                    "RUC: " + (empresa.EMP_RUC != null ? empresa.EMP_RUC : "--.--") + " " + (empresa.EMP_RAZON_SOC != null ? empresa.EMP_RAZON_SOC : ""),
                    "Dirección: " + (empresa.EMP_DIRECCION != null ? empresa.EMP_DIRECCION : "--.--"),
                    "Correo: " + (empresa.EMP_CORREO != null ? empresa.EMP_CORREO : "--.--") + ",  " +
                    "Nros telefónicos: " + (empresa.EMP_TELEFONO != null ? empresa.EMP_TELEFONO : "--.--"),
                    reporte.toUpperCase(),
                    "Descargado por: " + usuario + " el " + fecha_larga,
                    ""
                ];
            } else {
                state.user.cia.membrete = [
                    //empresa.EMP_NOMBRE.toUpperCase(),
                    //"RUC: " + (empresa.EMP_RUC != null ? empresa.EMP_RUC : "--.--") + " " + (empresa.EMP_RAZON_SOC != null ? empresa.EMP_RAZON_SOC : ""),
                    //"Dirección: " + (empresa.EMP_DIRECCION != null ? empresa.EMP_DIRECCION : "--.--"),
                    //"Correo: " + (empresa.EMP_CORREO != null ? empresa.EMP_CORREO : "--.--") + ",  " +
                    //"Nros telefónicos: " + (empresa.EMP_TELEFONO != null ? empresa.EMP_TELEFONO : "--.--"),
                    reporte.toUpperCase(),
                    "Descargado por: " + usuario + " el " + fecha_larga,
                    ""
                ];
            }
        },

        SET_FOOTER(state, payload) { // payload = agricultor nombre tipo / usuario

            state.user.cia.footer = [];
            state.user.cia.footer.push("Obs: Reporte generado a la fecha de descarga");
            // si se firm
            payload.nombres ?
                state.user.cia.footer.push("Firmas en señal de conformidad") &
                state.user.cia.footer.push("FIRMA " + payload.cargo + ": __________________________") &
                state.user.cia.footer.push(payload.cargo + ": " + payload.nombres) :
                state.user.cia.footer.push("Firma en señal de conformidad");
            state.user.cia.footer.push("FIRMA OPERARIO: __________________________");
            state.user.cia.footer.push("OPERARIO: " + state.user.user.first_name + ' ' + state.user.user.last_name);


        },
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_DATA_USER(state, payload) {
            console.log("SETDATA");
            console.log(payload);
            let currentUser = state.user.user;
            currentUser.username = payload.username;
            currentUser.first_name = payload.first_name;
            currentUser.last_name = payload.last_name;
            currentUser.telefono = payload.telefono;
            //currentUser.password= payload.password,
            currentUser.email = payload.email
        },

        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },

        auth_error(state) {
            state.status.loggedIn = false;
        },

    },
};
